import React from 'react';
import { ArrowUpCircle, ArrowDownCircle, MinusCircle } from 'lucide-react';

const formatCurrency = (amount) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(amount);
};  

const DailyOccupancyComparison = ({ occupancy, occupancyBudget, occupancyDiff }) => {
  const getDiffColor = (diff) => {
    if (diff > 0) return 'text-success';
    if (diff < 0) return 'text-error';
    return 'text-warning';
  };

  const getDiffIcon = (diff) => {
    if (diff > 0) return <ArrowUpCircle className="inline mr-1" size={20} />;
    if (diff < 0) return <ArrowDownCircle className="inline mr-1" size={20} />;
    return <MinusCircle className="inline mr-1" size={20} />;
  };

  const diffColor = getDiffColor(occupancyDiff);
  const diffIcon = getDiffIcon(occupancyDiff);

  return (
    <div className="text-sm">
      <div className=""><strong>Occupancy:</strong> <span className={`stat-desc ${diffColor}`}>{diffIcon}{occupancyDiff.toFixed(2)}%</span></div>
      <ul>
        <li><span>Actual: {occupancy.toFixed(1)}%  vs. Budget: {occupancyBudget.toFixed(1)}%</span></li>
        {/* <li><span className={`stat-desc ${diffColor}`}>{diffIcon} Difference: {occupancyDiff.toFixed(2)}%</span></li> */}
      </ul>
    </div>
  );
};

const MTDOccupancyComparison = ({ mtdOccupancy, mtdOccupancyBudget, mtdOccupancyDiff }) => {
  const getDiffColor = (diff) => {
    if (diff > 0) return 'text-success';
    if (diff < 0) return 'text-error';
    return 'text-warning';
  };

  const getDiffIcon = (diff) => {
    if (diff > 0) return <ArrowUpCircle className="inline mr-1" size={20} />;
    if (diff < 0) return <ArrowDownCircle className="inline mr-1" size={20} />;
    return <MinusCircle className="inline mr-1" size={20} />;
  };

  const diffColor = getDiffColor(mtdOccupancyDiff);
  const diffIcon = getDiffIcon(mtdOccupancyDiff);

  return (
    <div className="text-sm">
      <div className=""><strong>Occupancy:</strong> <span className={`stat-desc ${diffColor}`}>{diffIcon}{mtdOccupancyDiff.toFixed(2)}%</span></div>
      <ul>
        <li><span>Actual: {mtdOccupancy.toFixed(1)}%  vs. Budget: {mtdOccupancyBudget.toFixed(1)}%</span></li>
        {/* <li><span className={`stat-desc ${diffColor}`}>{diffIcon} Difference: {mtdOccupancyDiff.toFixed(2)}%</span></li> */}
      </ul>
    </div>
  );
};

const DailyRevParComparison = ({ revPar, revParBudget, revParDiff }) => {
  const getDiffColor = (diff) => {
    if (diff > 0) return 'text-success';
    if (diff < 0) return 'text-error';
    return 'text-warning';
  };

  const getDiffIcon = (diff) => {
    if (diff > 0) return <ArrowUpCircle className="inline mr-1" size={20} />;
    if (diff < 0) return <ArrowDownCircle className="inline mr-1" size={20} />;
    return <MinusCircle className="inline mr-1" size={20} />;
  };

  const diffColor = getDiffColor(revParDiff);
  const diffIcon = getDiffIcon(revParDiff);

  return (
    <div className="text-sm">
      <div className=""><strong>RevPAR:</strong> <span className={`stat-desc ${diffColor}`}>{diffIcon}{formatCurrency(revParDiff)}</span></div>
      <ul>
        <li><span>Actual: {formatCurrency(revPar)}  vs. Budget: {formatCurrency(revParBudget)}</span></li>
        {/* <li><span className={`stat-desc ${diffColor}`}>{diffIcon} Difference: {formatCurrency(revParDiff)}</span></li> */}
      </ul>
    </div>
  );
};

const MTDRevParComparison = ({ mtdRevPar, mtdRevParBudget, mtdRevParDiff }) => {
  const getDiffColor = (diff) => {
    if (diff > 0) return 'text-success';
    if (diff < 0) return 'text-error';
    return 'text-warning';
  };

  const getDiffIcon = (diff) => {
    if (diff > 0) return <ArrowUpCircle className="inline mr-1" size={20} />;
    if (diff < 0) return <ArrowDownCircle className="inline mr-1" size={20} />;
    return <MinusCircle className="inline mr-1" size={20} />;
  };

  const diffColor = getDiffColor(mtdRevParDiff);
  const diffIcon = getDiffIcon(mtdRevParDiff);

  return (
    <div className="text-sm">
      <div className=""><strong>RevPAR:</strong> <span className={`stat-desc ${diffColor}`}>{diffIcon}{formatCurrency(mtdRevParDiff)}</span></div>
      <ul>
        <li><span>Actual: {formatCurrency(mtdRevPar)}  vs. Budget: {formatCurrency(mtdRevParBudget)}</span></li>
        {/* <li><span className={`stat-desc ${diffColor}`}>{diffIcon} Difference: {formatCurrency(mtdRevParDiff)}</span></li> */}
      </ul>
    </div>
  );
};

const ADRComparison = ({ dailyADR, dailyADRBudget, dailyADRDiff }) => {
  const getDiffColor = (diff) => {
    if (diff > 0) return 'text-success';
    if (diff < 0) return 'text-error';
    return 'text-warning';
  };

  const getDiffIcon = (diff) => {
    if (diff > 0) return <ArrowUpCircle className="inline mr-1" size={20} />;
    if (diff < 0) return <ArrowDownCircle className="inline mr-1" size={20} />;
    return <MinusCircle className="inline mr-1" size={20} />;
  };

  const diffColor = getDiffColor(dailyADRDiff);
  const diffIcon = getDiffIcon(dailyADRDiff);

  return (
    <div className="text-sm">
      <div className=""><strong>Average Daily Rate:</strong> <span className={`stat-desc ${diffColor}`}>{diffIcon}{formatCurrency(dailyADRDiff)}</span></div>
      <ul>
        <li><span>Actual: {formatCurrency(dailyADR)}  vs. Budget: {formatCurrency(dailyADRBudget)}</span></li>
        {/* <li><span className={`stat-desc ${diffColor}`}>{diffIcon} Difference: {formatCurrency(dailyADRDiff)}</span></li> */}
      </ul>
    </div>
  );
};

const MTDADRComparison = ({ mtdADR, mtdADRBudget, mtdADRDiff }) => {
  const getDiffColor = (diff) => {
    if (diff > 0) return 'text-success';
    if (diff < 0) return 'text-error';
    return 'text-warning';
  };

  const getDiffIcon = (diff) => {
    if (diff > 0) return <ArrowUpCircle className="inline mr-1" size={20} />;
    if (diff < 0) return <ArrowDownCircle className="inline mr-1" size={20} />;
    return <MinusCircle className="inline mr-1" size={20} />;
  };

  const diffColor = getDiffColor(mtdADRDiff);
  const diffIcon = getDiffIcon(mtdADRDiff);

  return (
    <div className="text-sm">
      <div className=""><strong>Average Daily Rate:</strong> <span className={`stat-desc ${diffColor}`}>{diffIcon}{formatCurrency(mtdADRDiff)}</span></div>
      <ul>
        <li><span>Actual: {formatCurrency(mtdADR)} vs. Budget: {formatCurrency(mtdADRBudget)}</span></li>
        {/* <li><span className={`stat-desc ${diffColor}`}>{diffIcon} Difference: {formatCurrency(mtdADRDiff)}</span></li> */}
      </ul>
    </div>
  );
};

const DailyRoomsRevenueComparison = ({ roomsRevenue, roomsRevenueBudget, roomsRevenueDiff, roomsRevenueDiffPercentage }) => {
  const getDiffColor = (diff) => {
    if (diff > 0) return 'text-success';
    if (diff < 0) return 'text-error';
    return 'text-warning';
  };

  const getDiffIcon = (diff) => {
    if (diff > 0) return <ArrowUpCircle className="inline mr-1" size={20} />;
    if (diff < 0) return <ArrowDownCircle className="inline mr-1" size={20} />;
    return <MinusCircle className="inline mr-1" size={20} />;
  };

  const diffColor = getDiffColor(roomsRevenueDiff);
  const diffIcon = getDiffIcon(roomsRevenueDiff);

  return (
    <div className="text-sm">
      <div className=""><strong>Rooms Revenue:</strong> <span className={`stat-desc ${diffColor}`}>{diffIcon}{formatCurrency(roomsRevenueDiff)} ({roomsRevenueDiffPercentage.toFixed(1)}%)</span></div>
      <ul>
        <li><span>Actual: {formatCurrency(roomsRevenue)}  vs. Budget: {formatCurrency(roomsRevenueBudget)}</span></li>
        {/* <li><span className={`stat-desc ${diffColor}`}>{diffIcon} Difference: {formatCurrency(roomsRevenueDiff)}</span></li> */}
      </ul>
    </div>
  );
};


const MTDRoomsRevenueComparison = ({ mtdRoomsRevenue, mtdRoomsRevenueBudget, mtdRoomsRevenueDiff, mtdRoomsRevenueDiffPercentage }) => {
  const getDiffColor = (diff) => {
    if (diff > 0) return 'text-success';
    if (diff < 0) return 'text-error';
    return 'text-warning';
  };

  const getDiffIcon = (diff) => {
    if (diff > 0) return <ArrowUpCircle className="inline mr-1" size={20} />;
    if (diff < 0) return <ArrowDownCircle className="inline mr-1" size={20} />;
    return <MinusCircle className="inline mr-1" size={20} />;
  };

  const diffColor = getDiffColor(mtdRoomsRevenueDiff);
  const diffIcon = getDiffIcon(mtdRoomsRevenueDiff);

  return (
    <div className="text-sm">
      <div className=""><strong>Rooms Revenue:</strong> <span className={`stat-desc ${diffColor}`}>{diffIcon}{formatCurrency(mtdRoomsRevenueDiff)} ({mtdRoomsRevenueDiffPercentage.toFixed(1)}%)</span></div>
      <ul>
        <li><span>Actual: {formatCurrency(mtdRoomsRevenue)}  vs. Budget: {formatCurrency(mtdRoomsRevenueBudget)}</span></li>
        {/* <li><span className={`stat-desc ${diffColor}`}>{diffIcon} Difference: {formatCurrency(mtdRoomsRevenueDiff)}</span></li> */}
      </ul>
    </div>
  );
};


const RTavernDailyRevenueComparison = ({ rtavernRevenue, rtavernBudget, rtavernDiff }) => {
  const getDiffColor = (diff) => {
    if (diff > 0) return 'text-success';
    if (diff < 0) return 'text-error';
    return 'text-warning';
  };

  const getDiffIcon = (diff) => {
    if (diff > 0) return <ArrowUpCircle className="inline mr-1" size={20} />;
    if (diff < 0) return <ArrowDownCircle className="inline mr-1" size={20} />;
    return <MinusCircle className="inline mr-1" size={20} />;
  };

  const diffColor = getDiffColor(rtavernDiff);
  const diffIcon = getDiffIcon(rtavernDiff);

  return (
    <div className="text-sm">
      <div className=""><strong>Republic Tavern Revenue:</strong> <span className={`stat-desc ${diffColor}`}>{diffIcon}{formatCurrency(rtavernDiff)}</span></div>
      <ul>
        <li><span>Actual: {formatCurrency(rtavernRevenue)}  vs. Budget: {formatCurrency(rtavernBudget)}</span></li>
        {/* <li><span className={`stat-desc ${diffColor}`}>{diffIcon} Difference: {formatCurrency(rtavernDiff)}</span></li> */}
      </ul>
    </div>
  );
};

const MTDRTavernDailyRevenueComparison = ({ mtdrtavernRevenue, mtdrtavernBudget, mtdrtavernDiff }) => {
  const getDiffColor = (diff) => {
    if (diff > 0) return 'text-success';
    if (diff < 0) return 'text-error';
    return 'text-warning';
  };

  const getDiffIcon = (diff) => {
    if (diff > 0) return <ArrowUpCircle className="inline mr-1" size={20} />;
    if (diff < 0) return <ArrowDownCircle className="inline mr-1" size={20} />;
    return <MinusCircle className="inline mr-1" size={20} />;
  };

  const diffColor = getDiffColor(mtdrtavernDiff);
  const diffIcon = getDiffIcon(mtdrtavernDiff);

  return (
    <div className="text-sm">
      <div className=""><strong>Republic Tavern Revenue:</strong> <span className={`stat-desc ${diffColor}`}>{diffIcon}{formatCurrency(mtdrtavernDiff)}</span></div>
      <ul>
        <li><span>Actual: {formatCurrency(mtdrtavernRevenue)}  vs. Budget: {formatCurrency(mtdrtavernBudget)}</span></li>
        {/* <li><span className={`stat-desc ${diffColor}`}>{diffIcon} Difference: {formatCurrency(mtdrtavernDiff)}</span></li> */}
      </ul>
    </div>
  );
};

const TraderDailyRevenueComparison = ({ traderRevenue, traderBudget, traderDiff, traderRevExConf, roomServiceRevenue, confRevenue }) => {
  const getDiffColor = (diff) => {
    if (diff > 0) return 'text-success';
    if (diff < 0) return 'text-error';
    return 'text-warning';
  };

  const getDiffIcon = (diff) => {
    if (diff > 0) return <ArrowUpCircle className="inline mr-1" size={20} />;
    if (diff < 0) return <ArrowDownCircle className="inline mr-1" size={20} />;
    return <MinusCircle className="inline mr-1" size={20} />;
  };

  const diffColor = getDiffColor(traderDiff);
  const diffIcon = getDiffIcon(traderDiff);

return (
    <div className="text-sm my-0 leading-tight">
    <div className=""><strong>Trader & Conference Revenue:</strong> <span className={`stat-desc ${diffColor}`}>{diffIcon}{formatCurrency(traderDiff)}</span></div>
    <ul>
      <li><span>Actual: {formatCurrency(traderRevenue)}  vs. Budget: {formatCurrency(traderBudget)}</span></li>
      {/* <li><span className={`stat-desc ${diffColor}`}>{diffIcon} Difference: {formatCurrency(traderDiff)}</span></li> */}
    </ul>
    <div className=""><strong>Revenue Split:</strong></div>
    <ul>    
      <li>Trader Revenue: {formatCurrency(traderRevExConf)}</li>
      <li>Room Service Revenue: {formatCurrency(roomServiceRevenue)}</li>
      <li>C&amp;E Revenue (inc Room hire): {formatCurrency(confRevenue)}</li>
    </ul>
  </div>
  );
};

const MTDTraderDailyRevenueComparison = ({ mtdtraderRevenue, mtdtraderBudget, mtdtraderDiff, mtdtraderRevExConf, mtdroomServiceRevenue, mtdconfRevenue }) => {
  const getDiffColor = (diff) => {
    if (diff > 0) return 'text-success';
    if (diff < 0) return 'text-error';
    return 'text-warning';
  };

  const getDiffIcon = (diff) => {
    if (diff > 0) return <ArrowUpCircle className="inline mr-1" size={20} />;
    if (diff < 0) return <ArrowDownCircle className="inline mr-1" size={20} />;
    return <MinusCircle className="inline mr-1" size={20} />;
  };

  const diffColor = getDiffColor(mtdtraderDiff);
  const diffIcon = getDiffIcon(mtdtraderDiff);

return (
    <div className="text-sm my-0 leading-tight">
    <div className=""><strong>Trader & Conference Revenue:</strong> <span className={`stat-desc ${diffColor}`}>{diffIcon}{formatCurrency(mtdtraderDiff)}</span></div>
    <ul>
      <li><span>Actual: {formatCurrency(mtdtraderRevenue)}  vs. Budget: {formatCurrency(mtdtraderBudget)}</span></li>
      {/* <li><span className={`stat-desc ${diffColor}`}>{diffIcon} Difference: {formatCurrency(mtdtraderDiff)}</span></li> */}
    </ul>
    <div className=""><strong>Revenue Split:</strong></div>
    <ul>    
      <li>Trader Revenue: {formatCurrency(mtdtraderRevExConf)}</li>
      <li>Room Service Revenue: {formatCurrency(mtdroomServiceRevenue)}</li>
      <li>C&amp;E Revenue (inc Room hire): {formatCurrency(mtdconfRevenue)}</li>
    </ul>
  </div>
  );
};

const FBRevenueComparison = ({totalFBRevenue, totalFBBudget, totalFBDiff, totalFBDiffPercentage}) => { 
  const getDiffColor = (diff) => {
    if (diff > 0) return 'text-success';
    if (diff < 0) return 'text-error';
    return 'text-warning';
  };

  const getDiffIcon = (diff) => {
    if (diff > 0) return <ArrowUpCircle className="inline mr-1" size={20} />;
    if (diff < 0) return <ArrowDownCircle className="inline mr-1" size={20} />;
    return <MinusCircle className="inline mr-1" size={20} />;
  };

  const diffColor = getDiffColor(totalFBDiff);
  const diffIcon = getDiffIcon(totalFBDiff);

return (
    <div className="text-sm my-0 leading-tight">
    <div className=""><strong>Total F&B Revenue:</strong> <span className={`stat-desc ${diffColor}`}>{diffIcon}{formatCurrency(totalFBDiff)}  ({totalFBDiffPercentage.toFixed(1)}%)</span></div>
    <ul>
      <li><span>Actual: {formatCurrency(totalFBRevenue)}  vs. Budget: {formatCurrency(totalFBBudget)}</span></li>
      {/* <li><span className={`stat-desc ${diffColor}`}>{diffIcon} Difference: {formatCurrency(totalFBDiff)}</span></li> */}
    </ul>
  </div>
  );
};

const MTDFBRevenueComparison = ({mtdtotalFBRevenue, mtdtotalFBBudget, mtdtotalFBDiff, mtdtotalFBDiffPercentage}) => { 
  const getDiffColor = (diff) => {
    if (diff > 0) return 'text-success';
    if (diff < 0) return 'text-error';
    return 'text-warning';
  };

  const getDiffIcon = (diff) => {
    if (diff > 0) return <ArrowUpCircle className="inline mr-1" size={20} />;
    if (diff < 0) return <ArrowDownCircle className="inline mr-1" size={20} />;
    return <MinusCircle className="inline mr-1" size={20} />;
  };

  const diffColor = getDiffColor(mtdtotalFBDiff);
  const diffIcon = getDiffIcon(mtdtotalFBDiff);

return (
    <div className="text-sm my-0 leading-tight">
    <div className=""><strong>Total F&B Revenue:</strong> <span className={`stat-desc ${diffColor}`}>{diffIcon}{formatCurrency(mtdtotalFBDiff)} ({mtdtotalFBDiffPercentage.toFixed(1)}%)</span></div>
    <ul>
      <li><span>Actual: {formatCurrency(mtdtotalFBRevenue)}  vs. Budget: {formatCurrency(mtdtotalFBBudget)}</span></li>
      {/* <li><span className={`stat-desc ${diffColor}`}>{diffIcon} Difference: {formatCurrency(mtdtotalFBDiff)}</span></li> */}
    </ul>
  </div>
  );
};

const DashboardStats = ({ stats }) => {
  if (!stats || !stats.latestStats) {
    return <div className="alert alert-warning">No dashboard stats available</div>;
  }

  const { latestStats } = stats;

  const occupancy = (latestStats.PaidRoomsSold / latestStats.RoomsAvailable) * 100;
  const occupancyBudget = (latestStats.PaidRoomsSoldBudget / latestStats.RoomsAvailable) * 100;
  const occupancyDiff = (((latestStats.PaidRoomsSold - latestStats.PaidRoomsSoldBudget) / latestStats.RoomsAvailable) * 100);

  const mtdOccupancy = (latestStats.mtdPaidRoomsSold / latestStats.mtdPaidRoomsAvailable) * 100;
  const mtdOccupancyBudget = (latestStats.mtdPaidRoomsBudget / latestStats.mtdPaidRoomsAvailable) * 100;
  const mtdOccupancyDiff = (((latestStats.mtdPaidRoomsSold - latestStats.mtdPaidRoomsBudget) / latestStats.mtdPaidRoomsAvailable) * 100);

  const revPar = (latestStats.RevPar);
  const revParBudget = (latestStats.RevParBudget);
  const revParDiff = (latestStats.RevPar - latestStats.RevParBudget);

  const mtdRevpar = (latestStats.mtdRevPar);
  const mtdRevparBudget = (latestStats.mtdRevParBudget);
  const mtdRevParDiff = (latestStats.mtdRevPar - latestStats.mtdRevParBudget);

  const dailyADR = (latestStats.AvgDailyRate);
  const dailyADRBudget = (latestStats.AvgDailyRateBudget);
  const dailyADRDiff = (latestStats.AvgDailyRate - latestStats.AvgDailyRateBudget) ;

  const mtdADR = (latestStats.mtdADR);
  const mtdADRBudget = (latestStats.mtdADRBudget);
  const mtdADRDiff = (latestStats.mtdADR - latestStats.mtdADRBudget) ;

  const roomsRevenue = (latestStats.TotalRoomsRevenue);
  const roomsRevenueBudget = (latestStats.TotalRoomsRevenueBudget);
  const roomsRevenueDiff = (latestStats.TotalRoomsRevenue - latestStats.TotalRoomsRevenueBudget) ;
  const roomsRevenueDiffPercentage = (roomsRevenueDiff / roomsRevenueBudget) * 100 ;  
  
  const mtdRoomsRevenue = (latestStats.mtdRoomsRevenue);
  const mtdRoomsRevenueBudget = (latestStats.mtdRoomsRevenueBudget);
  const mtdRoomsRevenueDiff = (latestStats.mtdRoomsRevenue - latestStats.mtdRoomsRevenueBudget) ;
  const mtdRoomsRevenueDiffPercentage = (mtdRoomsRevenueDiff / mtdRoomsRevenueBudget) * 100 ;    
  
  const rtavernRevenue = (latestStats.RepublicTavernRevenue);
  const rtavernBudget = (latestStats.RepublicTavernBudget);
  const rtavernDiff = (latestStats.RepublicTavernRevenue - latestStats.RepublicTavernBudget) ;

  const mtdrtavernRevenue = (latestStats.MTDRepublicTavernRevenue);
  const mtdrtavernBudget = (latestStats.MTDRepublicTavernBudget);
  const mtdrtavernDiff = (latestStats.MTDRepublicTavernRevenue - latestStats.MTDRepublicTavernBudget) ;  
  
  const traderRevenue = (latestStats.TraderConferenceRevenue);
  const traderBudget = (latestStats.TraderConferenceRevenueBudget);
  const traderDiff = (latestStats.TraderConferenceRevenue - latestStats.TraderConferenceRevenueBudget);
  const traderRevExConf = (latestStats.TotalTraderRevenue);
  const roomServiceRevenue = (latestStats.TotalRoomServiceRevenue);
  const confRevenue = (latestStats.TotalConfRevenue);

  const mtdtraderRevenue = (latestStats.MTDTraderConferenceRevenue);
  const mtdtraderBudget = (latestStats.MTDTraderConferenceRevenueBudget);
  const mtdtraderDiff = (latestStats.MTDTraderConferenceRevenue - latestStats.MTDTraderConferenceRevenueBudget);
  const mtdtraderRevExConf = (latestStats.MTDTotalTraderRevenue);
  const mtdroomServiceRevenue = (latestStats.MTDTotalRoomServiceRevenue);
  const mtdconfRevenue = (latestStats.MTDTotalConfRevenue);

  const totalFBRevenue = (latestStats.TotalFBRevenue);
  const totalFBBudget = (latestStats.TotalFBRevenueBudget);
  const totalFBDiff = (latestStats.TotalFBRevenue - latestStats.TotalFBRevenueBudget);
  const totalFBDiffPercentage = (totalFBDiff / totalFBBudget) * 100;

  const mtdtotalFBRevenue = (latestStats.MTDTotalFBRevenue);
  const mtdtotalFBBudget = (latestStats.MTDTotalFBRevenueBudget);
  const mtdtotalFBDiff = (latestStats.MTDTotalFBRevenue - latestStats.MTDTotalFBRevenueBudget);  
  const mtdtotalFBDiffPercentage = (mtdtotalFBDiff / mtdtotalFBBudget) * 100;
  
  const formattedDate = new Date(latestStats.ForecastDate).toLocaleDateString('en-US', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });  

  const formattedMonthYear = new Date(latestStats.ForecastDate).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long'
  });  

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(amount);
  };  

  return (
    <div className="stats-container grid grid-cols-1 md:grid-cols-3 gap-4">
      <div className="stat-card bg-base-100 shadow-xl p-4 rounded-lg">
        <article className="prose">
          <h2 className="card-title"><button className="btn">Hotel Data: {formattedDate}</button></h2>              
          <blockquote className="">
          <DailyOccupancyComparison 
            occupancy={occupancy}
            occupancyBudget={occupancyBudget}
            occupancyDiff={occupancyDiff}
          />
          </blockquote>

          <blockquote className="">
          <DailyRevParComparison
            revPar={revPar}
            revParBudget={revParBudget}
            revParDiff={revParDiff}
          />
          </blockquote>          
          
          <blockquote className="">
          <ADRComparison
            dailyADR={dailyADR}
            dailyADRBudget={dailyADRBudget}
            dailyADRDiff={dailyADRDiff}
          />
          </blockquote>       

          <blockquote className="dashboardTotals">
          <DailyRoomsRevenueComparison
            roomsRevenue={roomsRevenue}
            roomsRevenueBudget={roomsRevenueBudget}
            roomsRevenueDiff={roomsRevenueDiff}
            roomsRevenueDiffPercentage={roomsRevenueDiffPercentage}
          />
          </blockquote>            

        </article>
      </div>

      <div className="stat-card bg-base-100 shadow-xl p-4 rounded-lg">
        <article className="prose">
          <h2 className="card-title"><button className="btn">F&B Data: {formattedDate}</button></h2>   
          <blockquote> 
            <RTavernDailyRevenueComparison   
              rtavernRevenue={rtavernRevenue}
              rtavernBudget={rtavernBudget}
              rtavernDiff={rtavernDiff}
              />
          </blockquote>            
          <blockquote className="my-0 py-0">
            <TraderDailyRevenueComparison
              traderRevenue={traderRevenue}
              traderBudget={traderBudget}
              traderDiff={traderDiff}
              traderRevExConf={traderRevExConf}
              roomServiceRevenue={roomServiceRevenue}
              confRevenue={confRevenue}
              />
          </blockquote>
          <blockquote className="dashboardTotals">
            <FBRevenueComparison
              totalFBRevenue={totalFBRevenue}
              totalFBBudget={totalFBBudget}
              totalFBDiff={totalFBDiff}
              totalFBDiffPercentage={totalFBDiffPercentage}
            />       
          </blockquote>
        </article>
      </div>        

      <div className="stat-card bg-base-100 shadow-xl p-4 rounded-lg">
        <article className="prose">
          <h2 className="card-title"><button className="btn">MTD Hotel Data: {formattedMonthYear}</button></h2>
          <blockquote className="">
          <MTDOccupancyComparison 
            mtdOccupancy={mtdOccupancy}
            mtdOccupancyBudget={mtdOccupancyBudget}
            mtdOccupancyDiff={mtdOccupancyDiff}
          />
          </blockquote>

          <blockquote className="">
          <MTDRevParComparison
            mtdRevPar={mtdRevpar}
            mtdRevParBudget={mtdRevparBudget}
            mtdRevParDiff={mtdRevParDiff}
          />
          </blockquote>          
          
          <blockquote className="">
          <MTDADRComparison
            mtdADR={mtdADR}
            mtdADRBudget={mtdADRBudget}
            mtdADRDiff={mtdADRDiff}
          />
          </blockquote>       

          <blockquote className="dashboardTotals">
          <MTDRoomsRevenueComparison
            mtdRoomsRevenue={mtdRoomsRevenue}
            mtdRoomsRevenueBudget={mtdRoomsRevenueBudget}
            mtdRoomsRevenueDiff={mtdRoomsRevenueDiff}
            mtdRoomsRevenueDiffPercentage={mtdRoomsRevenueDiffPercentage}
          />
          </blockquote>              
        </article>
      </div>

      <div className="stat-card bg-base-100 shadow-xl p-4 rounded-lg">
        <article className="prose">
          <h2 className="card-title"><button className="btn">MTD F&B Data: {formattedMonthYear}</button></h2>   
          <blockquote> 
            <MTDRTavernDailyRevenueComparison   
              mtdrtavernRevenue={mtdrtavernRevenue}
              mtdrtavernBudget={mtdrtavernBudget}
              mtdrtavernDiff={mtdrtavernDiff}
              />
          </blockquote>            
          <blockquote className="my-0 py-0">
            <MTDTraderDailyRevenueComparison
              mtdtraderRevenue={mtdtraderRevenue}
              mtdtraderBudget={mtdtraderBudget}
              mtdtraderDiff={mtdtraderDiff}
              mtdtraderRevExConf={mtdtraderRevExConf}
              mtdroomServiceRevenue={mtdroomServiceRevenue}
              mtdconfRevenue={mtdconfRevenue}
              />
          </blockquote>
          <blockquote className="dashboardTotals">
            <MTDFBRevenueComparison
              mtdtotalFBRevenue={mtdtotalFBRevenue}
              mtdtotalFBBudget={mtdtotalFBBudget}
              mtdtotalFBDiff={mtdtotalFBDiff}
              mtdtotalFBDiffPercentage={mtdtotalFBDiffPercentage}
            />       
          </blockquote>
        </article>
      </div>         

    </div>
  );
};

export default DashboardStats;