import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';
import 'chartjs-adapter-luxon';
import { DateTime } from 'luxon';
import ChartDateRange from './ChartDateRange';

const chartColors = [
  'rgba(0, 117, 220, 0.8)',
  'rgba(76, 175, 80, 0.8)',
  'rgba(244, 67, 54, 0.8)',
  'rgba(255, 152, 0, 0.8)',
  'rgba(156, 39, 176, 0.8)',
  'rgba(0, 150, 136, 0.8)',
  'rgba(233, 30, 99, 0.8)',
  'rgba(205, 220, 57, 0.8)',
  'rgba(63, 81, 181, 0.8)',
  'rgba(0, 188, 212, 0.8)',
  'rgba(255, 87, 34, 0.8)',
  'rgba(139, 195, 74, 0.8)',
  'rgba(255, 193, 7, 0.8)',
  'rgba(121, 85, 72, 0.8)',
  'rgba(103, 58, 183, 0.8)',
  'rgba(96, 125, 139, 0.8)',
  'rgba(255, 235, 59, 0.8)',
  'rgba(158, 158, 158, 0.8)',
  'rgba(3, 169, 244, 0.8)',
  'rgba(124, 179, 66, 0.8)',
];

const HotelOccupancyOccupiedRoomsChart = ({ occupancyData }) => {
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);

  // Define the initially visible room types
  const initialVisibleRoomTypes = ['AllRooms', 'HTL', 'DHTL'];

  // Determine all available room types from occupancyData
  const allRoomTypes = Array.from(new Set(occupancyData.map(data => data.RoomType)));

  // Initialize visibleRoomTypes with the initially visible types
  const [visibleRoomTypes, setVisibleRoomTypes] = useState(initialVisibleRoomTypes);

  // Compute the start date of the current month and today's date
  const startOfMonth = DateTime.now().startOf('month').toISODate();
  const today = DateTime.now().toISODate();
  const thirtyDaysAgo = DateTime.now().minus({ days: 30 }).toISODate();

  const [selectedDateRange, setSelectedDateRange] = useState({
    // start: startOfMonth,
    start: thirtyDaysAgo,
    end: today
  });

  const handleDateRangeChange = (event) => {
    const { name, value } = event.target;
    setSelectedDateRange((prevDateRange) => ({
      ...prevDateRange,
      [name]: value,
    }));
  };

  useEffect(() => {
    const ctx = chartRef.current.getContext('2d');

    // Destroy the previous chart instance if it exists
    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy();
    }

    // Create a mapping of room types to colors from the chartColors array
    const roomTypeColors = allRoomTypes.reduce((map, roomType, index) => {
      map[roomType] = chartColors[index % chartColors.length];
      return map;
    }, {});

    // Filter occupancy data based on selected date range
    const filteredOccupancyData = occupancyData.filter(data => {
      const dateInRange = !selectedDateRange.start || !selectedDateRange.end ||
        (DateTime.fromISO(data.Calendar_Date) >= DateTime.fromISO(selectedDateRange.start) &&
          DateTime.fromISO(data.Calendar_Date) <= DateTime.fromISO(selectedDateRange.end));
      return dateInRange;
    });

    // Create a mapping of dates to their corresponding data points
    const dateDataMap = filteredOccupancyData.reduce((map, data) => {
      const date = DateTime.fromISO(data.Calendar_Date).toFormat('yyyy-MM-dd');
      if (!map[date]) {
        map[date] = {};
      }
      map[date][data.RoomType] = data.OccupiedRooms;
      return map;
    }, {});

    // Create the labels and datasets for the chart
    const labels = Object.keys(dateDataMap);
    const datasets = allRoomTypes.map(roomType => ({
      label: roomType,
      data: labels.map(date => dateDataMap[date][roomType] || null),
      borderColor: roomTypeColors[roomType],
      fill: false,
      hidden: !visibleRoomTypes.includes(roomType),
    }));

    // Create a new chart instance
    chartInstanceRef.current = new Chart(ctx, {
      type: 'line',
      data: {
        labels: labels,
        datasets: datasets,
      },
      options: {
        scales: {
          x: {
            type: 'time',
            time: {
              unit: 'day',
              displayFormats: {
                day: 'EEE MMM d',
              },
              tooltipFormat: 'EEE MMM d, yyyy',
            },
          },
          y: {
            beginAtZero: true,
            title: {
              display: true,
              text: 'Rooms Occupied',
            },
          },
        },
        elements: {
          line: {
            tension: 0.4,
          },
        },
        onClick: (event, elements) => {
          if (elements.length > 0) {
            const datasetIndex = elements[0].datasetIndex;
            const roomType = chartInstanceRef.current.data.datasets[datasetIndex].label;

            if (visibleRoomTypes.includes(roomType)) {
              setVisibleRoomTypes(visibleRoomTypes.filter(rt => rt !== roomType));
            } else {
              setVisibleRoomTypes([...visibleRoomTypes, roomType]);
            }
          }
        },
      },
    });
  }, [occupancyData, visibleRoomTypes, selectedDateRange]);

  return (
    <>
      <ChartDateRange 
        selectedDateRange={selectedDateRange}
        handleDateRangeChange={handleDateRangeChange}
      />
      <canvas ref={chartRef} />
    </>
  );
};

export default HotelOccupancyOccupiedRoomsChart;
