import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';
import 'chartjs-adapter-luxon';
import { DateTime } from 'luxon';
import ChartDateRange from './ChartDateRange';

const chartColors = [
  'rgba(0, 117, 220, 0.8)',
  'rgba(76, 175, 80, 0.8)',
  'rgba(244, 67, 54, 0.8)',
  // ... (rest of the colors)
];

const HotelOccupancyAvgRateChart = ({ occupancyData }) => {
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);

  const initialVisibleRoomTypes = ['AllRooms', 'HTL', 'DHTL'];
  const [visibleRoomTypes, setVisibleRoomTypes] = useState(initialVisibleRoomTypes);

  const startOfMonth = DateTime.now().startOf('month').toISODate();
  const today = DateTime.now().toISODate();
  const thirtyDaysAgo = DateTime.now().minus({ days: 30 }).toISODate();

  const [selectedDateRange, setSelectedDateRange] = useState({
    // start: startOfMonth,
    start: thirtyDaysAgo,
    end: today
  });

  const handleDateRangeChange = (event) => {
    const { name, value } = event.target;
    setSelectedDateRange((prevDateRange) => ({
      ...prevDateRange,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (!Array.isArray(occupancyData) || occupancyData.length === 0) {
      console.error('Invalid or empty occupancy data');
      return;
    }

    const ctx = chartRef.current.getContext('2d');

    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy();
    }

    const allRoomTypes = Array.from(new Set(occupancyData.map(data => data.RoomType)));
    const roomTypeColors = allRoomTypes.reduce((map, roomType, index) => {
      map[roomType] = chartColors[index % chartColors.length];
      return map;
    }, {});

    const filteredOccupancyData = occupancyData.filter(data => {
      const dateInRange = !selectedDateRange.start || !selectedDateRange.end ||
        (DateTime.fromISO(data.Calendar_Date) >= DateTime.fromISO(selectedDateRange.start) &&
          DateTime.fromISO(data.Calendar_Date) <= DateTime.fromISO(selectedDateRange.end));
      return dateInRange;
    });

    const dateDataMap = filteredOccupancyData.reduce((map, data) => {
      const date = DateTime.fromISO(data.Calendar_Date).toFormat('yyyy-MM-dd');
      if (!map[date]) {
        map[date] = {};
      }
      map[date][data.RoomType] = data.AvgNightlyRateOverZero;
      return map;
    }, {});

    const labels = Object.keys(dateDataMap);
    const datasets = allRoomTypes.map(roomType => ({
      label: roomType,
      data: labels.map(date => dateDataMap[date][roomType] || null),
      borderColor: roomTypeColors[roomType],
      fill: false,
      hidden: !visibleRoomTypes.includes(roomType),
    }));

    chartInstanceRef.current = new Chart(ctx, {
      type: 'line',
      data: {
        labels: labels,
        datasets: datasets,
      },
      options: {
        scales: {
          x: {
            type: 'time',
            time: {
              unit: 'day',
              displayFormats: {
                day: 'EEE MMM d',
              },
              tooltipFormat: 'EEE MMM d, yyyy',
            },
          },
          y: {
            beginAtZero: true,
            title: {
              display: true,
              text: 'Avg Nightly Rate Over Zero',
            },
          },
        },
        elements: {
          line: {
            tension: 0.4,
          },
        },
        onClick: (event, elements) => {
          if (elements.length > 0) {
            const datasetIndex = elements[0].datasetIndex;
            const roomType = chartInstanceRef.current.data.datasets[datasetIndex].label;

            if (visibleRoomTypes.includes(roomType)) {
              setVisibleRoomTypes(visibleRoomTypes.filter(rt => rt !== roomType));
            } else {
              setVisibleRoomTypes([...visibleRoomTypes, roomType]);
            }
          }
        },
      },
    });
  }, [occupancyData, visibleRoomTypes, selectedDateRange]);

  if (!Array.isArray(occupancyData) || occupancyData.length === 0) {
    return <div>No occupancy data available</div>;
  }

  return (
    <>
      <ChartDateRange 
        selectedDateRange={selectedDateRange}
        handleDateRangeChange={handleDateRangeChange}
      />
      <canvas ref={chartRef} />
    </>
  );
};

export default HotelOccupancyAvgRateChart;