import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [username, setUsername] = useState('');
  const [token, setToken] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkAuthStatus = () => {
      const storedToken = localStorage.getItem('token');
      const storedUsername = localStorage.getItem('username');
      console.log('Checking auth status. Token:', storedToken, 'Username:', storedUsername);
      if (storedToken && storedUsername) {
        setIsAuthenticated(true);
        setUsername(storedUsername);
        setToken(storedToken);
      } else {
        setIsAuthenticated(false);
        setUsername('');
        setToken('');
      }
      setIsLoading(false);
    };

    checkAuthStatus();
    window.addEventListener('storage', checkAuthStatus);

    return () => {
      window.removeEventListener('storage', checkAuthStatus);
    };
  }, []);

  const login = (newToken, user) => {
    console.log('Logging in. Token:', newToken, 'User:', user);
    localStorage.setItem('token', newToken);
    localStorage.setItem('username', user);
    setIsAuthenticated(true);
    setUsername(user);
    setToken(newToken);
  };

  const logout = () => {
    console.log('Logging out');
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    setIsAuthenticated(false);
    setUsername('');
    setToken('');
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, username, token, login, logout, isLoading }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);