import React from 'react';
import { Link } from 'react-router-dom';
import LogoutButton from './LogoutButton';
import { HomeIcon, BuildingOfficeIcon, BuildingStorefrontIcon } from '@heroicons/react/24/solid';
import { useAuth } from './AuthContext';

const Navbar = ({ isSidebarOpen, toggleSidebar }) => {
  const { username } = useAuth();

  return (
    <div className={`bg-base-100 fixed top-0 left-0 h-screen transition-all duration-300 ${isSidebarOpen ? 'w-48' : 'w-16'} overflow-hidden`}>
      <div className="flex flex-col h-full">
        <div className="flex items-center justify-between p-4">
          <button className="btn btn-ghost p-0" onClick={toggleSidebar}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              className={`inline-block w-6 h-6 stroke-current transition-transform duration-300 ${
                isSidebarOpen ? 'transform rotate-180' : ''
              }`}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              ></path>
            </svg>
          </button>
        </div>
        
        <ul className="menu p-2">
          <li>
            <Link to="/dashboard" className="flex items-center p-2">
              <HomeIcon className="h-6 w-6 mr-2" />
              {isSidebarOpen && <span>Dashboard</span>}
            </Link>
          </li>          
          <li>
            <Link to="/hotel-data" className="flex items-center p-2">
              <BuildingOfficeIcon className="h-6 w-6 mr-2" />
              {isSidebarOpen && <span>Hotel Data</span>}
            </Link>
          </li>
          <li>
            <Link to="/tavern-data" className="flex items-center p-2">
              <BuildingStorefrontIcon className="h-6 w-6 mr-2" />
              {isSidebarOpen && <span>Tavern Data</span>}
            </Link>
          </li>
{/* 
          <li><a>Item 1</a></li>
          <li>
            <details open>
              <summary>Parent</summary>
              <ul>
                <li><a>Submenu 1</a></li>
                <li><a>Submenu 2</a></li>
                <li>
                  <details open>
                    <summary>Parent</summary>
                    <ul>
                      <li><a>Submenu 1</a></li>
                      <li><a>Submenu 2</a></li>
                    </ul>
                  </details>
                </li>
              </ul>
            </details>
          </li>
          <li><a>Item 3</a></li> */}


        </ul>
        <div className="mt-auto p-4">
          <LogoutButton isSidebarOpen={isSidebarOpen} />
          {isSidebarOpen && username && (
          <div className="chat chat-start text-xs">
          <div className="chat-bubble chat-bubble-primary text-xs"><em>Logged in as {username}</em></div>
        </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;