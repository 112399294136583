import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';
import 'chartjs-adapter-luxon';
import { DateTime } from 'luxon';
import ChartDateRange from './ChartDateRange';

const SevenDayForecast = ({ forecastData }) => {
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);

  const [selectedDateRange, setSelectedDateRange] = useState({
    start: DateTime.now().toISODate(),
    end: DateTime.now().plus({ days: 6 }).toISODate()
  });

  const handleDateRangeChange = (event) => {
    const { name, value } = event.target;
    setSelectedDateRange((prevDateRange) => ({
      ...prevDateRange,
      [name]: value,
    }));
  };

  useEffect(() => {
    const ctx = chartRef.current.getContext('2d');

    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy();
    }

    const filteredForecastData = forecastData.filter(data => {
      const dateInRange = DateTime.fromISO(data.date) >= DateTime.fromISO(selectedDateRange.start) &&
        DateTime.fromISO(data.date) <= DateTime.fromISO(selectedDateRange.end);
      return dateInRange;
    });

    const labels = filteredForecastData.map(data => data.date);
    const arrData = filteredForecastData.map(data => data.arr);
    const occupancyData = filteredForecastData.map(data => data.occupancy * 100);
    const inHouseGuestsData = filteredForecastData.map(data => data.inHouseGuests);
    const monthlyBudgetedOccupancy = forecastData[0].monthlyBudgetedOccupancy;
    const monthlyBudgetedADR = forecastData[0].MonthlyBudgetADR;
    

    const getBackgroundColor = (context) => {
      const index = context.dataIndex;
      const date = DateTime.fromISO(labels[index]);
      const dayOfWeek = date.weekday;
      return dayOfWeek === 6 || dayOfWeek === 7 ? 'rgba(96, 193, 97, 0.6)' : 'rgba(54, 162, 235, 0.6)';
    };

    chartInstanceRef.current = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [
          {
            label: 'Average Room Rate ($)',
            data: arrData,
            backgroundColor: getBackgroundColor,
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 1,
            yAxisID: 'y',
            order: 2
          },
          {
            label: 'Occupancy Percentage (%)',
            data: occupancyData,
            type: 'line',
            borderColor: 'rgba(255, 99, 132, 1)',
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
            fill: false,
            yAxisID: 'y1',
            tension: 0.4,
            order: 1
          },
          // {
          //   label: 'In House Guests (#)',
          //   data: inHouseGuestsData,
          //   type: 'line',
          //   borderColor: 'rgba(75, 192, 192, 1)',
          //   backgroundColor: 'rgba(75, 192, 192, 0.2)',
          //   fill: false,
          //   yAxisID: 'y2',
          //   tension: 0.4,
          //   order: 0
          // },
          {
            label: ' Budgeted Occupancy (%)',
            data: labels.map(() => monthlyBudgetedOccupancy.toFixed(1)),
            type: 'line',
            borderColor: 'rgba(255, 69, 0, 1)', // Brighter orange color
            backgroundColor: 'rgba(255, 69, 0, 0.2)', // Light fill color
            borderWidth: 3, // Increased line thickness
            borderDash: [10, 5], // Longer dash pattern
            fill: true, // Add a light fill below the line
            pointRadius: 0,
            yAxisID: 'y1',
            order: 3,
            tension: 0, // Straight line segments
            z: 10 // Bring the line to the front
          },
          {
            label: 'Budgeted Room Rate ($)',
            data: labels.map(() => monthlyBudgetedADR.toFixed(2)),
            type: 'line',
            borderColor: 'rgba(0, 0, 0, 1)', // Brighter orange color
            backgroundColor: 'rgba(255, 69, 0, 0.2)', // Light fill color
            borderWidth: 3, // Increased line thickness
            borderDash: [100, 5], // Longer dash pattern
            fill: false, // Add a light fill below the line
            pointRadius: 0,
            yAxisID: 'y',
            order: 3,
            tension: 0, // Straight line segments
            z: 10 // Bring the line to the front
          }                 
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
          mode: 'index',
          intersect: false,
        },
        plugins: {
          title: {
            display: true,
            text: 'Hotel Occupancy - 7 Day Forecast'
          }
        },
        scales: {
          x: {
            type: 'time',
            time: {
              unit: 'day',
              displayFormats: {
                day: 'EEE MMM d',
              },
              tooltipFormat: 'EEE MMM d, yyyy',
            },
          },
          y: {
            type: 'linear',
            display: true,
            position: 'left',
            title: {
              display: true,
              text: 'Average Room Rate ($)',
            },
          },
          y1: {
            type: 'linear',
            display: true,
            position: 'right',
            title: {
              display: true,
              text: 'Occupancy Percentage (%)',
            },
            min: 0,
            max: 100,
            ticks: {
              stepSize: 20,
            },
            grid: {
              drawOnChartArea: false,
            },
          },
          // y2: {
          //   type: 'linear',
          //   display: true,
          //   position: 'right',
          //   title: {
          //     display: true,
          //     text: 'In House Guests',
          //   },
          //   grid: {
          //     drawOnChartArea: false,
          //   },
          // },
        },
      },
    });
  }, [forecastData, selectedDateRange]);

  return (
    <div className="mt-8">
      <h2 className="">
      <button className="btn">Hotel Occupancy - 7 Day Forecast</button></h2>
      
      {/* <ChartDateRange
        selectedDateRange={selectedDateRange}
        handleDateRangeChange={handleDateRangeChange}
      /> */}
      
      {/* <div className="mb-8" style={{ width: '100%', height: 400 }}>
        <canvas ref={chartRef} />
      </div> */}
      <div className="chart-wrapper mb-8 w-full" style={{ height: '400px' }}>
        <canvas ref={chartRef} />
      </div>      

      {/* <div className="overflow-x-auto">
        <table className="table-zebra table table-xs">
          <thead>
            <tr>
              <th>Forecast Date</th>
              <th>In House Guests</th>
              <th>Occupancy (%)</th>
              <th>ARR (net) $</th>
            </tr>
          </thead>
          <tbody>
            {forecastData.map((day, index) => (
              <tr key={index}>
                <td>{day.date}</td>
                <td>{day.inHouseGuests}</td>
                <td>{(day.occupancy * 100).toFixed(2)}%</td>
                <td>${day.arr.toFixed(2)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div> */}
    </div>
  );
};

export default SevenDayForecast;