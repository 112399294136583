import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';
import 'chartjs-adapter-luxon';
import { DateTime } from 'luxon';
import ChartDateRange from './ChartDateRange';

const HotelOccupancyChart = ({ occupancyData }) => {
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);
  const [visibleRoomTypes, setVisibleRoomTypes] = useState(['AllRooms', '1BRM', 'HTL', 'DHTL']);

  // Compute the start date of the current month and today's date
  const startOfMonth = DateTime.now().startOf('month').toISODate();
  const today = DateTime.now().toISODate();
  const thirtyDaysAgo = DateTime.now().minus({ days: 30 }).toISODate();

  const [selectedDateRange, setSelectedDateRange] = useState({
    // start: startOfMonth,
    start: thirtyDaysAgo,
    end: today
  });

  const handleDateRangeChange = (event) => {
    const { name, value } = event.target;
    setSelectedDateRange((prevDateRange) => ({
      ...prevDateRange,
      [name]: value,
    }));
  };

  useEffect(() => {
    const ctx = chartRef.current.getContext('2d');

    // Destroy the previous chart instance if it exists
    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy();
    }

    // Create a mapping of room types to colors
    const roomTypeColors = occupancyData.reduce((map, data, index) => {
      if (!map[data.RoomType]) {
        map[data.RoomType] = `rgba(${(index * 70) % 255}, ${(index * 130) % 255}, ${(index * 200) % 255}, 1)`;
      }
      return map;
    }, {});

    // Filter occupancy data based on visible room types and selected date range
    const filteredOccupancyData = occupancyData.filter(data => {
      const isVisibleRoomType = visibleRoomTypes.includes(data.RoomType);
      const dateInRange = !selectedDateRange.start || !selectedDateRange.end ||
        (DateTime.fromISO(data.Calendar_Date) >= DateTime.fromISO(selectedDateRange.start) &&
          DateTime.fromISO(data.Calendar_Date) <= DateTime.fromISO(selectedDateRange.end));
      return isVisibleRoomType && dateInRange;
    });

    // Create a mapping of dates to their corresponding data points
    const dateDataMap = filteredOccupancyData.reduce((map, data) => {
      const date = DateTime.fromISO(data.Calendar_Date).toFormat('yyyy-MM-dd');
      if (!map[date]) {
        map[date] = {};
      }
      map[date].avgRate = data.AvgNightlyRateOverZero;
      map[date].occupancy = data.PercentageOccupied;
      return map;
    }, {});

    // Create the labels and datasets for the chart
    const labels = Object.keys(dateDataMap);
    const avgRateData = labels.map(date => dateDataMap[date].avgRate);
    const occupancyPercentageData = labels.map(date => dateDataMap[date].occupancy);

    // Create a function to determine the background color based on the day of the week
    const getBackgroundColor = (context) => {
      const index = context.dataIndex;
      const date = DateTime.fromISO(labels[index]);
      const dayOfWeek = date.weekday;
      return dayOfWeek === 6 || dayOfWeek === 7 ? 'rgba(96, 193, 97, 0.6)' : 'rgba(54, 162, 235, 0.6)';
    };

    // Create a new chart instance
    chartInstanceRef.current = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [
          {
            label: 'Average Nightly Rate',
            data: avgRateData,
            backgroundColor: getBackgroundColor,
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 1,
            yAxisID: 'y',
          },
          {
            label: 'Occupancy Percentage',
            data: occupancyPercentageData,
            type: 'line',
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
            borderColor: 'rgba(255, 99, 132, 1)',
            fill: false,
            yAxisID: 'y1',
          },
          {
            label: '50% Occupancy',
            data: labels.map(() => 50),
            type: 'line',
            borderColor: 'rgba(255, 0, 0, 0.5)',
            borderWidth: 2,
            borderDash: [5, 5],
            fill: false,
            pointRadius: 0,
            yAxisID: 'y1',
            order: 3
          },          
        ],
      },
      options: {
        scales: {
          x: {
            type: 'time',
            time: {
              unit: 'day',
              displayFormats: {
                day: 'EEE MMM d',
              },
              tooltipFormat: 'EEE MMM d, yyyy',
            },
          },
          y: {
            beginAtZero: true,
            position: 'left',
            title: {
              display: true,
              text: 'Average Nightly Rate',
            },
          },
          y1: {
            beginAtZero: true,
            position: 'right',
            grid: {
              drawOnChartArea: false,
            },
            title: {
              display: true,
              text: 'Occupancy Percentage',
            },
            min: 0,
            max: 100,            
          },
        },
        elements: {
          line: {
            tension: 0.4,
          },
        },
        onClick: (event, elements) => {
          if (elements.length > 0) {
            const datasetIndex = elements[0].datasetIndex;
            const roomType = chartInstanceRef.current.data.datasets[datasetIndex].label;
            if (visibleRoomTypes.includes(roomType)) {
              setVisibleRoomTypes(visibleRoomTypes.filter(rt => rt !== roomType));
            } else {
              setVisibleRoomTypes([...visibleRoomTypes, roomType]);
            }
          }
        },
      },
    });
  }, [occupancyData, visibleRoomTypes, selectedDateRange]);

  return (
    <>
      <ChartDateRange
        selectedDateRange={selectedDateRange}
        handleDateRangeChange={handleDateRangeChange}
      />
      <canvas ref={chartRef} />
    </>
  );
};

export default HotelOccupancyChart;