import React from 'react';

const Navbar = () => {
  return (

    <div className="navbar bg-base-100">
      <span className="text-xl">
        <img 
          // src="https://images.squarespace-cdn.com/content/v1/64d456c7ff78564d8f4025e2/ee43c1d9-dc85-4943-8890-56a8a7993c24/toobuild_green_rgb.jpg?format=102w" 
          src="./too_build_logo.png"
          className="max-w-sm rounded-lg" 
          alt="Logo"
        />
      </span>
    </div>
  );
};

export default Navbar;