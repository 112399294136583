import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from './AuthContext';
import Layout from './Layout';
import Navbar from './components/Navbar';
import DashboardStats from './components/DashboardStats';
import SevenDayForecast from './components/SevenDayForecast';
// import LeadTimeStatsTable from './components/LeadTimeStatsTable';
import LeadTimeStatsChart from './components/LeadTimeStatsChart';

const Dashboard = () => {
  const [dashboardStats, setDashboardStats] = useState(null);
  const [forecastData, setForecastData] = useState([]);
  const [leadTimeStats, setLeadTimeStats] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { isAuthenticated, token } = useAuth();

  const fetchData = async () => {
    if (isAuthenticated && token) {
      try {
        const [statsResponse, forecastResponse, leadTimeResponse] = await Promise.all([
          axios.get('/api/dashboard-stats', {
            headers: { Authorization: `Bearer ${token}` }
          }),
          axios.get('/api/seven-day-forecast', {
            headers: { Authorization: `Bearer ${token}` }
          }),
          axios.get('/api/lead-time-stats', {
            headers: { Authorization: `Bearer ${token}` }
          })          
        ]);
        setDashboardStats(statsResponse.data);
        setForecastData(forecastResponse.data);
        setLeadTimeStats(leadTimeResponse.data);
        setIsLoading(false);
        setError(null);
      } catch (error) {
        console.error('Error fetching data:', error.response || error);
        setError(`Failed to load dashboard data: ${error.response?.data?.details || error.message}`);
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [isAuthenticated, token]);

  const handleReload = () => {
    setIsLoading(true);
    setError(null);
    fetchData();
  };

  if (isLoading) {
    return (
      <Layout>
        <Navbar />
        <div className="mt-8 flex flex-col gap-4 w-52">
          <div className="skeleton h-32 w-full"></div>
          <div className="skeleton h-4 w-28"></div>
          <div className="skeleton h-4 w-full"></div>
          <div className="skeleton h-4 w-full"></div>
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <Navbar />
      {/* <h2 className="">
      <button className="btn">Mantra Epping - Hotel Data Dashboard</button></h2> */}
      {error ? (
        <div className="alert alert-error mt-4">
          {error}
          <button onClick={handleReload} className="btn btn-sm btn-outline ml-4">
            Reload Data
          </button>
        </div>
      ) : (
        <>
          {dashboardStats ? (
            <DashboardStats stats={dashboardStats} />
          ) : (
            <div className="alert alert-warning">No dashboard stats available</div>
          )}
         
          {forecastData.length > 0 && (
            <SevenDayForecast forecastData={forecastData} />
          )}
         


          {/* {leadTimeStats.length > 0 && (
            <div className="mt-8">
              <h2 className="text-xl font-bold mb-4">Lead Time Statistics (Last 12 Months)</h2>
              <LeadTimeStatsTable stats={leadTimeStats} />
            </div>
          )} */}
        </>
      )}
    </Layout>
  );
};

export default Dashboard;