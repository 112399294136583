import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';

const DEFAULT_VISIBLE_TYPES = ['AllRooms', 'HTL', 'DHTL'];

const LeadTimeStatsChart = ({ stats }) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);
  const [visibleRoomTypes, setVisibleRoomTypes] = useState(DEFAULT_VISIBLE_TYPES);

  useEffect(() => {
    if (chartRef.current) {
      const ctx = chartRef.current.getContext('2d');
      
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
      
      const leadTimeGroups = [...new Set(stats.map(stat => stat.LeadTimeGroup))];
      const allRoomTypes = [...new Set(stats.map(stat => stat.RoomType))];
      
      const datasets = allRoomTypes.flatMap(roomType => [
        {
          label: `${roomType} - Avg Rate`,
          type: 'bar',
          data: leadTimeGroups.map(group => 
            stats.find(stat => stat.RoomType === roomType && stat.LeadTimeGroup === group)?.AvgNightlyRate || 0
          ),
          backgroundColor: getRandomColor(0.6),
          yAxisID: 'y',
          hidden: !visibleRoomTypes.includes(roomType),
        },
        {
          label: `${roomType} - Booking %`,
          type: 'line',
          data: leadTimeGroups.map(group => 
            stats.find(stat => stat.RoomType === roomType && stat.LeadTimeGroup === group)?.BookingPercentage || 0
          ),
          borderColor: getRandomColor(1),
          fill: false,
          yAxisID: 'y1',
          hidden: !visibleRoomTypes.includes(roomType),
        }
      ]);

      chartInstance.current = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: leadTimeGroups,
          datasets: datasets,
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: 'top',
              onClick: (evt, item, legend) => {
                const clickedRoomType = item.text.split(' - ')[0];
                setVisibleRoomTypes(prev => 
                  prev.includes(clickedRoomType)
                    ? prev.filter(type => type !== clickedRoomType)
                    : [...prev, clickedRoomType]
                );
              }
            },
            title: {
              display: true,
              text: 'Average Nightly Rate and Booking Percentage by Lead Time and Room Type'
            }
          },
          scales: {
            x: {
              title: {
                display: true,
                text: 'Lead Time'
              }
            },
            y: {
              type: 'linear',
              display: true,
              position: 'left',
              title: {
                display: true,
                text: 'Average Nightly Rate ($)'
              },
              beginAtZero: true
            },
            y1: {
              type: 'linear',
              display: true,
              position: 'right',
              title: {
                display: true,
                text: 'Booking Percentage (%)'
              },
              beginAtZero: true,
              max: 100,
              grid: {
                drawOnChartArea: false,
              },
            }
          }
        }
      });
    }

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [stats, visibleRoomTypes]);

  function getRandomColor(alpha) {
    const r = Math.floor(Math.random() * 255);
    const g = Math.floor(Math.random() * 255);
    const b = Math.floor(Math.random() * 255);
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }

  return <canvas ref={chartRef} />;
};

export default LeadTimeStatsChart;