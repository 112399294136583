//TavernData.js
import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import Chart from 'chart.js/auto';
import { DateTime } from 'luxon';
import Layout from './Layout';
import Navbar from './components/Navbar';
import ChartDateRange from './components/ChartDateRange';

const chartColors = [
  'rgba(0, 117, 220, 0.8)',
  'rgba(76, 175, 80, 0.8)',
  'rgba(244, 67, 54, 0.8)',
  'rgba(255, 152, 0, 0.8)',
  'rgba(156, 39, 176, 0.8)',
  'rgba(0, 150, 136, 0.8)',
  'rgba(233, 30, 99, 0.8)',
  'rgba(205, 220, 57, 0.8)',
  'rgba(63, 81, 181, 0.8)',
  'rgba(0, 188, 212, 0.8)',
  'rgba(255, 87, 34, 0.8)',
  'rgba(139, 195, 74, 0.8)',
  'rgba(255, 193, 7, 0.8)',
  'rgba(121, 85, 72, 0.8)',
  'rgba(103, 58, 183, 0.8)',
  'rgba(96, 125, 139, 0.8)',
  'rgba(255, 235, 59, 0.8)',
  'rgba(158, 158, 158, 0.8)',
  'rgba(3, 169, 244, 0.8)',
  'rgba(124, 179, 66, 0.8)',
];

const TavernData = () => {
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);
  const tapDrinksChartRef = useRef(null);
  const tapDrinksChartInstanceRef = useRef(null);
  const tavernFoodChartRef = useRef(null);
  const tavernFoodChartInstanceRef = useRef(null);
  const [tavernData, setTavernData] = useState([]);
  const [occupancyData, setOccupancyData] = useState([]);
  const [tapDrinksSalesData, setTapDrinksSalesData] = useState([]);
  const [tavernFoodSalesData, setTavernFoodSalesData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const startOfMonth = DateTime.now().startOf('month').toISODate();
  const today = DateTime.now().toISODate();
  const thirtyDaysAgo = DateTime.now().minus({ days: 30 }).toISODate();

  const [selectedDateRange, setSelectedDateRange] = useState({
    // start: startOfMonth,
    start: thirtyDaysAgo,
    end: today
  });




  const [selectedProductNames, setSelectedProductNames] = useState([
    'Beer Republic Lager',
    'Beer Republic XPA',
    'Beer Republic Pacific Pale Ale',
  ]);

  const [selectedFoodItems, setSelectedFoodItems] = useState([
    'Parma',
    'Beef Burger',
    'Chicken Parmigiana',
    'Fish & Chips',
  ]);

  const handleDateRangeChange = (event) => {
    const { name, value } = event.target;
    setSelectedDateRange((prevDateRange) => ({
      ...prevDateRange,
      [name]: value,
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [tavernResponse, occupancyResponse] = await Promise.all([
          axios.get('/api/tavern-stats'),
          axios.get('/api/occupancy-data-all-rooms'),
        ]);
        setTavernData(tavernResponse.data.dailyTavernStats);
        setOccupancyData(occupancyResponse.data);
        setTapDrinksSalesData(tavernResponse.data.tapDrinksSalesData);
        setTavernFoodSalesData(tavernResponse.data.tavernFoodSalesData);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (tavernData.length === 0 || occupancyData.length === 0) return;

    const ctx = chartRef.current.getContext('2d');

    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy();
    }

    const filteredTavernData = tavernData.filter((data) => {
      const dateInRange =
        !selectedDateRange.start ||
        !selectedDateRange.end ||
        (DateTime.fromISO(data.TransactionDate) >= DateTime.fromISO(selectedDateRange.start) &&
          DateTime.fromISO(data.TransactionDate) <= DateTime.fromISO(selectedDateRange.end));
      return dateInRange;
    });

    const filteredOccupancyData = occupancyData.filter((data) => {
      const dateInRange =
        !selectedDateRange.start ||
        !selectedDateRange.end ||
        (DateTime.fromISO(data.Calendar_Date) >= DateTime.fromISO(selectedDateRange.start) &&
          DateTime.fromISO(data.Calendar_Date) <= DateTime.fromISO(selectedDateRange.end));
      return dateInRange;
    });

    const labels = filteredTavernData.map((data) =>
      DateTime.fromISO(data.TransactionDate).toFormat('yyyy-MM-dd')
    );
    const revenueData = filteredTavernData.map((data) => data.TotalPayment);
    const occupancyPercentageData = labels.map((date) => {
      const matchingOccupancyData = filteredOccupancyData.find((occupancyData) =>
        DateTime.fromISO(occupancyData.Calendar_Date).hasSame(DateTime.fromISO(date), 'day')
      );
      return matchingOccupancyData ? matchingOccupancyData.PercentageOccupied : null;
    });

    const getBackgroundColor = (context) => {
      const index = context.dataIndex;
      const date = DateTime.fromISO(labels[index]);
      const dayOfWeek = date.weekday;
      return dayOfWeek === 6 || dayOfWeek === 7 ? 'rgba(96, 193, 97, 0.6)' : 'rgba(54, 162, 235, 0.6)';
    };

    chartInstanceRef.current = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [
          {
            label: 'Republic Tavern Daily Revenue ($)',
            data: revenueData,
            backgroundColor: getBackgroundColor,
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 1,
            yAxisID: 'y',
          },
          {
            label: 'Hotel Occupancy (%)',
            data: occupancyPercentageData,
            type: 'line',
            borderColor: 'rgba(0, 0, 0, 1)',
            fill: false,
            yAxisID: 'y1',
            spanGaps: true,
            tension: 0.4,
          },
          {
            label: '50% Occupancy',
            data: labels.map(() => 50),
            type: 'line',
            borderColor: 'rgba(255, 0, 0, 0.5)',
            borderWidth: 2,
            borderDash: [5, 5],
            fill: false,
            pointRadius: 0,
            yAxisID: 'y1',
            order: 3
          }            
        ],
      },
      options: {
        scales: {
          x: {
            type: 'time',
            time: {
              unit: 'day',
              displayFormats: {
                day: 'EEE MMM d',
              },
              tooltipFormat: 'EEE MMM d, yyyy',
            },
          },
          y: {
            beginAtZero: true,
            position: 'left',
            title: {
              display: true,
              text: 'Republic Tavern Revenue ($)',
            },
          },
          y1: {
            beginAtZero: true,
            position: 'right',
            grid: {
              drawOnChartArea: false,
            },
            title: {
              display: true,
              text: 'Hotel Occupancy (%)',
            },
            min: 0,
            max: 100,             
          },
        },
      },
    });
  }, [tavernData, occupancyData, selectedDateRange]);

  useEffect(() => {
    if (tapDrinksSalesData.length === 0) return;

    const ctx = tapDrinksChartRef.current.getContext('2d');

    if (tapDrinksChartInstanceRef.current) {
      tapDrinksChartInstanceRef.current.destroy();
    }

    const filteredTapDrinksSalesData = tapDrinksSalesData.filter((data) => {
      const dateInRange =
        !selectedDateRange.start ||
        !selectedDateRange.end ||
        (DateTime.fromISO(data.SaleDate) >= DateTime.fromISO(selectedDateRange.start) &&
          DateTime.fromISO(data.SaleDate) <= DateTime.fromISO(selectedDateRange.end));
      return dateInRange;
    });

    const productNames = [...new Set(filteredTapDrinksSalesData.map((data) => data.ProductName))];
    const labels = [...new Set(filteredTapDrinksSalesData.map((data) => DateTime.fromISO(data.SaleDate).toFormat('yyyy-MM-dd')))];

    const datasets = productNames.map((productName, index) => {
      const salesData = labels.map((date) => {
        const matchingData = filteredTapDrinksSalesData.find(
          (data) =>
            data.ProductName === productName && DateTime.fromISO(data.SaleDate).toFormat('yyyy-MM-dd') === date
        );
        return matchingData ? matchingData.TotalSales : null;
      });
    
      const color = chartColors[index % chartColors.length];
    
      return {
        label: productName,
        data: salesData,
        borderColor: color,
        backgroundColor: color,
        fill: false,
        tension: 0.4,
        hidden: !selectedProductNames.includes(productName),
      };
    });

    tapDrinksChartInstanceRef.current = new Chart(ctx, {
      type: 'line',
      data: {
        labels: labels,
        datasets: datasets,
      },
      options: {
        scales: {
          x: {
            type: 'time',
            time: {
              unit: 'day',
              displayFormats: {
                day: 'EEE MMM d',
              },
              tooltipFormat: 'EEE MMM d, yyyy',
            },
          },
          y: {
            beginAtZero: true,
            title: {
              display: true,
              text: 'Tap Drinks Sales ($)',
            },
          },
        },
        plugins: {
          legend: {
            onClick: (e, legendItem, legend) => {
              const index = legend.chart.data.datasets.findIndex((dataset) => dataset.label === legendItem.text);
              const isHidden = legend.chart.isDatasetVisible(index);

              if (isHidden) {
                legend.chart.hide(index);
                setSelectedProductNames((prevSelectedProductNames) =>
                  prevSelectedProductNames.filter((productName) => productName !== legendItem.text)
                );
              } else {
                legend.chart.show(index);
                setSelectedProductNames((prevSelectedProductNames) => [...prevSelectedProductNames, legendItem.text]);
              }
            },
          },
        },
      },
    });
  }, [tapDrinksSalesData, selectedDateRange, selectedProductNames]);

  useEffect(() => {
    if (tavernFoodSalesData.length === 0) return;

    const ctx = tavernFoodChartRef.current.getContext('2d');

    if (tavernFoodChartInstanceRef.current) {
      tavernFoodChartInstanceRef.current.destroy();
    }

    const filteredTavernFoodSalesData = tavernFoodSalesData.filter((data) => {
      const dateInRange =
        !selectedDateRange.start ||
        !selectedDateRange.end ||
        (DateTime.fromISO(data.SaleDate) >= DateTime.fromISO(selectedDateRange.start) &&
          DateTime.fromISO(data.SaleDate) <= DateTime.fromISO(selectedDateRange.end));
      return dateInRange;
    });

    const foodItems = [...new Set(filteredTavernFoodSalesData.map((data) => data.ProductName))];
    const labels = [...new Set(filteredTavernFoodSalesData.map((data) => DateTime.fromISO(data.SaleDate).toFormat('yyyy-MM-dd')))];

    const datasets = foodItems.map((item, index) => {
      const salesData = labels.map((date) => {
        const matchingData = filteredTavernFoodSalesData.find(
          (data) =>
            data.ProductName === item && DateTime.fromISO(data.SaleDate).toFormat('yyyy-MM-dd') === date
        );
        return matchingData ? matchingData.TotalSales : null;
      });
    
      const color = chartColors[index % chartColors.length];
    
      return {
        label: item,
        data: salesData,
        borderColor: color,
        backgroundColor: color,
        fill: false,
        tension: 0.4,
        hidden: !selectedFoodItems.includes(item),
      };
    });

    tavernFoodChartInstanceRef.current = new Chart(ctx, {
      type: 'line',
      data: {
        labels: labels,
        datasets: datasets,
      },
      options: {
        scales: {
          x: {
            type: 'time',
            time: {
              unit: 'day',
              displayFormats: {
                day: 'EEE MMM d',
              },
              tooltipFormat: 'EEE MMM d, yyyy',
            },
          },
          y: {
            beginAtZero: true,
            title: {
              display: true,
              text: 'Tavern Food Sales ($)',
            },
          },
        },
        plugins: {
          legend: {
            onClick: (e, legendItem, legend) => {
              const index = legend.chart.data.datasets.findIndex((dataset) => dataset.label === legendItem.text);
              const isHidden = legend.chart.isDatasetVisible(index);

              if (isHidden) {
                legend.chart.hide(index);
                setSelectedFoodItems((prevSelectedFoodItems) =>
                  prevSelectedFoodItems.filter((item) => item !== legendItem.text)
                );
              } else {
                legend.chart.show(index);
                setSelectedFoodItems((prevSelectedFoodItems) => [...prevSelectedFoodItems, legendItem.text]);
              }
            },
          },
        },
      },
    });
  }, [tavernFoodSalesData, selectedDateRange, selectedFoodItems]);

    return (
      <Layout>
      <Navbar />
      <h1 className="">Republic Tavern Data</h1>
      {isLoading ? (
        // <div className="flex justify-center items-center mt-8">
        //   <span className="loading loading-bars loading-sm"></span>
        // </div>
        <div className="mt-8 flex flex-col gap-4 w-52">
          <div className="skeleton h-32 w-full"></div>
          <div className="skeleton h-4 w-28"></div>
          <div className="skeleton h-4 w-full"></div>
          <div className="skeleton h-4 w-full"></div>
        </div>        
      ) : (
        <>
          <div className="mt-8">
            <h2 className="">
              <button className="btn">Republic Tavern Daily Revenue ($) ex GST and Hotel Occupancy (%)</button>
            </h2>
            <ChartDateRange
              selectedDateRange={selectedDateRange}
              handleDateRangeChange={handleDateRangeChange}
            />
            <div className="chart-container">
              <canvas ref={chartRef} />
            </div>
          </div>
          <div className="mt-8">
            <h2 className="">
              <button className="btn">Tap Drinks Sales ($)</button>
            </h2>
            <ChartDateRange
              selectedDateRange={selectedDateRange}
              handleDateRangeChange={handleDateRangeChange}
            />
            <div className="chart-container">
              <canvas ref={tapDrinksChartRef} />
            </div>
          </div>
          <div className="mt-8">
            <h2 className="">
              <button className="btn">Tavern Food Sales ($)</button>
            </h2>
            <ChartDateRange
              selectedDateRange={selectedDateRange}
              handleDateRangeChange={handleDateRangeChange}
            />
            <div className="chart-container">
              <canvas ref={tavernFoodChartRef} />
            </div>
          </div>
        </>
      )}
    </Layout>
    );
};

export default TavernData;