import React from 'react';

const ChartDateRange = ({ selectedDateRange, handleDateRangeChange }) => {
  return (
<table className="table table-xs max-w-sm">
<tbody>
  <tr>
    <td className="">
      <label>Start:</label>
      <input
        type="date"
        name="start"
        value={selectedDateRange.start || ''}
        onChange={handleDateRangeChange}
      />
    </td>

    <td className="">
      <label>End:</label>
      <input
        type="date"
        name="end"
        value={selectedDateRange.end || ''}
        onChange={handleDateRangeChange}
      />
    </td>
  </tr>
</tbody>
</table>
  );
};

export default ChartDateRange;